import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';

import ResultsSummary from 'components/results-summary/ResultsSummary';
import { useInterval } from 'utils/useInterval';

import './MainJumbotron.scss';

const MainJumbotron = () => {
  const {
    textContentQuery,
    jumbotronPicsQuery,
    bryanPicQuery,
  } = useStaticQuery(graphql`
    query {
      textContentQuery: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(LandingPage.md)$/" } }
      ) {
        edges {
          node {
            frontmatter {
              mainJumbotronContent
            }
          }
        }
      }
      jumbotronPicsQuery: allFile(
        sort: { fields: name, order: ASC }
        filter: { relativeDirectory: { eq: "jumbotron" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 700, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      bryanPicQuery: allFile(
        filter: { relativeDirectory: { eq: "bryan-pic" } }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 500, quality: 80) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const [jumbotronIndex, setJumbotronIndex] = useState(0);

  useInterval(() => {
    setJumbotronIndex((prevIndex) =>
      prevIndex + 1 === jumbotronPicsQuery.edges.length ? 0 : prevIndex + 1
    );
  }, 3000);

  const jumbotronPic = jumbotronPicsQuery.edges[jumbotronIndex].node;
  const bryanPic = bryanPicQuery.edges[0].node;
  const textContent = textContentQuery.edges[0].node;

  return (
    <div className="main-jumbotron">
      <div className="left">
        <div className="top-content">
          <div className="intro">
            <div className="intro-pic-container">
              <Img
                fluid={bryanPic.childImageSharp.fluid}
                fadeIn={true}
                alt={bryanPic.name}
                draggable={false}
                className="intro-circle-pic"
              />
              <Link to="/about-me" className="about-me">
                About Me
                <span className="far fa-chevron-right" />
              </Link>
            </div>
            <div className="intro-details">
              <div className="name-wrapper">
                <span className="intro-name">Mr. Bryan</span>
              </div>
              <div className="credentials">
                <div className="row">
                  <span className="bullet">‣</span>
                  <span className="credential-item">
                    Graduated from NUS School of Economics
                  </span>
                </div>
                <div className="row">
                  <span className="bullet">‣</span>
                  <span className="credential-item">
                    Established since 2016
                  </span>
                </div>
                <div className="row">
                  <span className="bullet">‣</span>
                  <span className="credential-item">
                    Coached Over 100 Students
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="content"
            dangerouslySetInnerHTML={{
              __html: textContent.frontmatter.mainJumbotronContent,
            }}
          />
          <div className="row-center">
            <ResultsSummary />
          </div>
        </div>
      </div>
      <div className="right">
        <Img
          fluid={jumbotronPic.childImageSharp.fluid}
          fadeIn={true}
          key={jumbotronPic.id}
          alt={jumbotronPic.name}
          draggable={false}
          className="jumbotron-image"
        />
      </div>
    </div>
  );
};

export default MainJumbotron;
