import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import './ResultsSummary.scss';

const ResultsSummary = () => {
  const { contentQuery } = useStaticQuery(graphql`
    query {
      contentQuery: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(ResultsSummary.md)$/" } }
      ) {
        edges {
          node {
            frontmatter {
              year
              studentsImprovedPercentage
              gradesOfImprovement
              studentsScoredABPercentage
            }
          }
        }
      }
    }
  `);

  const {
    node: { frontmatter: summary },
  } = contentQuery.edges[0];

  const {
    studentsImprovedPercentage,
    gradesOfImprovement,
    studentsScoredABPercentage,
  } = summary;

  const [improvedCountUp, setImprovedCountUp] = useState(0);
  const [gradesCountUp, setGradesCountUp] = useState(0);
  const [scoredABCountUp, setScoredABCountUp] = useState(0);

  const improvedStep = studentsImprovedPercentage / 100;
  const gradesStep = gradesOfImprovement / 100;
  const scoredABStep = studentsScoredABPercentage / 100;

  useEffect(() => {
    const studentsImprovedInterval = setInterval(() => {
      setImprovedCountUp((prevValue: number) => {
        if (prevValue === studentsImprovedPercentage) {
          clearInterval(studentsImprovedInterval);
          return studentsImprovedPercentage;
        }
        return +(prevValue + improvedStep).toFixed(1);
      });
    }, 20);

    const gradesInterval = setInterval(() => {
      setGradesCountUp((prevValue: number) => {
        if (prevValue === gradesOfImprovement) {
          clearInterval(gradesInterval);
          return gradesOfImprovement;
        }
        return +(prevValue + gradesStep).toFixed(3);
      });
    }, 20);

    const scoredABInterval = setInterval(() => {
      setScoredABCountUp((prevValue: number) => {
        if (prevValue === studentsScoredABPercentage) {
          clearInterval(scoredABInterval);
          return studentsScoredABPercentage;
        }
        return +(prevValue + scoredABStep).toFixed(0);
      });
    }, 20);

    return () => {
      clearInterval(studentsImprovedInterval);
      clearInterval(gradesInterval);
      clearInterval(scoredABInterval);
    };
  }, []);

  return (
    <div className="results-summary">
      <div className="heading">
        <span>STUDENT&apos;S RESULTS</span>
      </div>
      <div className="rows-container">
        <div className="row">
          <span>
            Students <br />
            Improved
          </span>
          <span className="result">{improvedCountUp.toFixed(0)}%</span>
        </div>
        <div className="row">
          <span>
            Grades of <br />
            Improvement
          </span>
          <span className="result">{gradesCountUp.toFixed(1)}</span>
        </div>
        <div className="row">
          <span>
            Students <br />
            Scored A/B
          </span>
          <span className="result">{scoredABCountUp.toFixed(0)}%</span>
        </div>
      </div>
    </div>
  );
};

export default ResultsSummary;
