import React from 'react';

import Layout from 'components/layout/Layout';
import SEO from 'components/seo';
import MainJumbotron from 'components/main-jumbotron/MainJumbotron';

const IndexPage = () => (
  <Layout>
    <SEO title="AKMaths" />
    <MainJumbotron />
  </Layout>
);

export default IndexPage;
